@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css";
.form-container{
    width: 100%;
    max-width: 350px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
    padding: 1px 40px 70px;
    text-align: center;
    height: 400px;
    border-radius: 20px;
    border: 1px solid orange;
    box-shadow: 15px 18px 10px rgb(66, 56, 56);
    &_signup_warning{
        color:red;
        animation: blink 2s infinite;
    }

}
@keyframes blink{
    0%{
      opacity: 0;
    }
    50%{
      opacity: 0.7;
    }
    100%{
      opacity: 0;
    }
}
.form-box h1{
    font-size: 20px !important;
    font-family: "Poppins" sans-serif;
    color: #3c00a0;
    position: relative;
}
hr {
    color: rgb(143, 31, 31);
    border: 0.5px solid rgb(228, 226, 226);
  }
.form-box h1::after{
    content: '';
    width: 20px;
    height: 4px;
    background-color: #3c00a0;
    position: absolute;
    bottom: -5px;
    left: 50%;
}

.input-fields{
    padding: 5px;
    border-radius:3px;
    display: flex;
    align-items: center;
    background-color: rgb(255, 255, 255);
}
input{
    background: transparent;
    width: 100%;
    padding: 8px 38px;
    outline-color: orange;
    caret-color: black;
    border-radius:8px;
}
i{
    color: #3c00a0;
    border-radius: 5px;
  width: 50px;
  height: 20px;
  padding-left: 0px;
  z-index: 1;
}
form p{
    color: gray;
    font-size: 8px;
}
.btn-group{
    padding-top:10px;
    //width: 100%;
    display: flex;
    justify-content: space-evenly;
}
.btn-group button{
    flex-basis: 40%;
    background-color: #3c00a0;
    color: #fff;
    border-radius: 18px;
    border: 0;
  outline: 0;
  height: 40px;
//   transition-property: margin-right;

}
.btn-group button:hover{
    flex-basis: 40%;
    background-color: #9b6ee7;
    outline: 0;
    border: 0;
    cursor: pointer;
}