@import "https://kit.fontawesome.com/d573eb1cc4.js";
.header{
    // width: 100%;
    width: unquote($string: "min(90%,190px)");
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    //margin-left: 5px;
   // background-color: #e2e9f7 !important;
    &_side-nav{
        //width: 164px;
        // height: 91.2vh;
        height: 100vh;
        background-color: $brand_home_baground_color;
        margin-top: 5rem;
        //padding: 20px 30px;
        //padding-top: 5rem;
        padding-left: 1rem;
        // padding-right: 1rem;
        // box-shadow:0 0 12px 5px rgba(122, 173, 164);
        //border: 1px solid gray;
        //border-radius: 10px;
        //box-shadow: 10px 6px 12px rgb(255 255 255 / 10%);
        
        position: fixed;
        //justify-content: space-evenly;
        

        
        &_link_items{
            display: flex;
            flex-direction: row;
            padding: 1rem;
            gap: 0.4rem;
            margin: 3px;
            color: #fff;
            align-content: center;
            align-items: center;
            text-decoration: none;
            width: 100%;
            &:hover{
                margin: 3px;
                background-color: $brand_hovering_effect_color;
                padding: 1rem;
                //height making noise when hovering in between two tags shakin them
                //height: 40px;
                color: orange;
                border-radius: 20px 0 0 20px;
                //box-shadow: 0 10px rgba(255, 255,255,0.1);
                 justify-content:center;
                align-items: center;
                width: 100%;
                text-decoration: none;
                transition:  0.5s;

            }            
        }
    }
}
.header_side-nav_logo{
    margin-bottom: 30px;
    display: block;
    
    //border-bottom: 2px solid #fff;
}


.menu_togle, .menu_togle h3{
    display: inline-block;
    padding-left: 1rem;;
}
.mobile_header_side-nav_link_items{
    display: none;
}


@media only screen and (max-width:600px){
    
    
    svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa{
        display: block;
        
    }
    .header {
        // display: block;
    }
    .header_side-nav h3{
        display: none;
    }
    .header_side-nav{
        width:12%;
        align-items: center;
        padding:1rem 0;
        margin-top: 4.4rem;
        //height: 85.5%;
        height: 100vh;
        z-index: 0;
        position: fixed;
        // top:0;
         //position:relative;
        // inset: 0;
        // z-index: 2;
        &_link_items span {
            display: none;
    
        }
    }
    
   
    
}
