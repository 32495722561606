.underconstruction{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: auto;;
    min-height: 50vh;
    
    &_img{
        filter: opacity(70%);
        height: 200px;
        border-radius: 20px;
        width: auto;
    }

}