@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css";
// *{
//     margin: 0px;;
// }
.blog_container{
    background-color: $brand_baground_blogs_color;
    
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    flex-direction: column;
    margin: auto;
    padding: 10px;
    
}

.blog_container img{
    width:50%;
}
.blog_container_modelimage{
    max-width: 350px;
    filter: opacity(90%);
    border-style: none;
    border-width: 2px;
    padding-bottom: 20px;
}
// img{
//     width: 75%;
// }
.blog_container p{
    text-align: start;
    text-align-last:left;
    text-transform: capitalize;
    margin: 10px;
    margin-right: auto;
    align-items: center;
    // text-indent: 2%;
}
h2{
    font-size: 1.2rem !important;
    // margin: 20px;

}
.regression_img{
    align-items: center;
    justify-items: center;
    width: 50%;
    height: 30%;
}
.confusionmetrics{
    width: 50%;
}

//for profile card

// * {
//     margin: 0;
//     padding: 0;
//     box-sizing: border-box;
//     text-decoration: none;
//     transition: 0.3s;
// }

// body {
//     font-family: "Montserrat";
//     background-color: #b8b6b6;
//     color: #fdfdfd;
// }

// .card {
//     max-width: 250px;
//     margin: 150px auto 0;
//     background-color: #42515a;
//     box-shadow: 0 10px 90px #00000024;
//     text-align: center;
//     font-size: 20px;
//     border-radius: 15px;
// }

// .card .card-header {
//     position: relative;
//     height: 48px;
// }


// .card .card-header .profile-img {
//     width: 130px;
//     height: 130px;
//     border-radius: 1000px;
//     //position: absolute;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     border: 8px solid #c74385;
//     box-shadow: 0 0 20px #00000033;
//     border-radius: 20px;
// }

// .card .card-header .profile-img:hover {
//     width: 180px;
//     height: 180px;
//     border: 8px solid #d885af;
// }


// .card .card-body {
//     padding: 10px 40px;
// }

// .card .card-body .name {
//     margin-top: 30px;
//     font-size: 22px;
//     font-weight: bold;
//     color: #c74385;
// }

// .card .card-body .name:hover {
//     margin-top: 30px;
//     font-size: 24px;
//     color: #d885af;
// }

// .card .card-body .mail {
//     font-size: 14px;
//     color: #c2bdbd;
// }

// .card .card-body .mail:hover {
//     font-size: 16px;
//     color: #ffffff;
// }

// .card .card-body .job {
//     margin-top: 10px;
//     font-size: 14px;
// }


// .card .social-links {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin-top: 30px;
// }

// .card .social-links .social-icon {
//     display: inline-flex;
//     align-items: center;
//     justify-content: center;
//     height: 40px;
//     width: 40px;
//     background-color: #c74385;
//     color: #ffffff;
//     font-size: 20px;
//     border-radius: 100%;
//     text-decoration: none;
//     margin: 0 13px 30px 0;
// }

// .card .social-links .social-icon:last-child {
//     margin-right: 0;
// }

// .card .social-links .social-icon:hover {
//     background-color: #d885af;
//     height: 50px;
//     width: 50px;
//     text-decoration: none;
// }


// .card .card-footer {
//     background-color: #c74385;
//     border-bottom-left-radius: 15px;
//     border-bottom-right-radius: 15px;
//     padding: 20px 0 20px 0;
// }

// .card .card-footer .count {
//     font-size: 14px;
// }


// @media screen and (max-width: 575px) {
//     .card {
//         width: 96%;
//     }

//     .card .card-body {
//         padding: 10px 20px;
//     }
// }

.card-header_container{
    display: flex;
    flex-direction: row;
    padding: 30px;
    border: 2px solid rgb(224, 228, 204);
    border-radius: 13px;
    background-color: #b7c961;
    align-items: center;
    justify-content: center;
    text-align: center;
    justify-items: center;

}

.vl{
    border-right: 1px solid rgb(115, 119, 115);
    height: 200px;
    padding: 20px;
    align-items: center;
}
.card-header img{
     border-radius: 50%;
     -webkit-transform: scaleX(-1);
     transform: scaleX(-1);
    // image-orientation:4turn;
    transform: rotate(15deg);
    height: 30%;
    width: 50%;
    // border-image-width: 50%;
    align-items: center;
    justify-items: center;
    
}
.card-body p{
    align-items: center;
    margin-left: 45px !important;
}
// .card-body{
//     align-items: center;
// }