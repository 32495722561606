.notifications_container{
    margin-left: 10%;
    align-items: center;
    // height: 100%;
   //added for bg-color effect
    height: 100vh;
    background-color:#ebeff7 !important;
    &_title{
        text-align: center;
        text-transform: uppercase;
        font-family: "DM Sans",sans-serif;
        font-weight: bolder;
        align-items: center;
        gap: 0.4rem;
        color: rgb(54, 22, 199);
        & hr{
            height: 0.01px !important;
            border: 0.01px solid #60b4b1;
        }
    }
}