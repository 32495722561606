@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");
.dashboard_container{
    margin-left: 10%;
    align-items: center;
    height: 100vh;
    text-align: center;
    
    background-color:$brand_hovering_effect_color !important;
    &_title{
        text-transform: uppercase;
        text-align: center;
        font-family: "DM Sans",sans-serif;
        gap: 0.4rem;
        color: rgb(54, 22, 199);
        font-weight: bolder;
        h1{
            position: sticky;
            top: 1rem;
        }
    }& hr {
        height: 0.1px !important;
        //border: 0.01px solid #d9e3e3;
        border: 0.01px solid #60b4b1 transparent;
      }
}
i {
    color: #fff;
  }