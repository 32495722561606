.notfound{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    align-items: center;
    &__img{
        min-height: 300px;
    }
}
p{
    font-size: 1.2rem;
}
