.brand_home{
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    font-size: 2rem;
    margin: auto;
}
.brand_home_welcome{
    color: black;
    font-size: 2rem !important;
}