.settings_container{
    margin-left: 10%;
    align-items: center;
    // height: 100%;
    //added for bg-color effect
    height: 100vh;
    background-color:#ebeff7 !important;
    &_title{
        text-transform: uppercase;
        text-align: center;
        font-family: "DM Sans",sans-serif;
        gap: 0.4rem;
        color: rgb(54, 22, 199);
        font-weight: bolder;
    }& hr {
        height: 0.1px !important;
        border: 0.01px solid #60b4b1;
      }
}