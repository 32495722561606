.brand_header{
    display: flex;
    justify-content: space-evenly;
    padding: 1rem;
   // background-color: #15b5b0;
   background-color: $brand_primary_color;
    align-items: center;
   position: sticky;
    inset: 0;
    z-index: 2;
    &_links{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 50%;
        font-family: "Poppins",sans-serif;
        color: white;
        &_items{
            color: white;
        }
    }
}


.logo {
    display: flex;
    flex-direction: column;
    left: 25px;
    color: #fafafa;
    font-weight: bold;
    font-family: "Poppins", sans-serif;
  }

@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css";

.mobile-menu {
    display: none;
  }
@media screen and (max-width: 780px) {
    .mobile-menu {
      display: block;
      position: absolute;
      font-size: 20px;
      color: white;
      background-color: #15b5b0;
      right: 10px;
      outline: none;
      border: 2px solid white;
      cursor: pointer;
    }
    .mobile-menu .fa, .fas {
        font-weight: 900;
        color: white;
        width: auto;
    }
    
    .brand_header {
      padding: 1.2rem;
      display: flex;
      justify-content: space-between;
    }
    .logo {
      display: flex;
      // flex-direction: column;
      justify-content: flex-start;
      left: 5px;
      color: #fafafa;
      font-weight: bold;
      font-family: "Poppins", sans-serif;
    }
  
    .brand_header_links {
      display: none;
    }
    .brand_header_links_mobile {
      display: block;
      position: fixed;
      background-color: #15b5b0;
      left: 0;
      top: 5.4rem;
      transition: all 0.5s ease-out;
      width: 25%;
      height: 100vh;
      margin-left: 75%;
      text-transform: uppercase;
      :hover {
        color: orange;
        text-decoration: none;
        
      }
    }
    .brand_header_links_items {
      color: white;
      text-align: center;
      font-size: medium;
      font-weight: 500;
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      transition: all 0.5s ease;
    }
  }