.brand_footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    margin-top: auto;
    background-color: $brand_primary_color;
    //color: $brand_white_color;
    font-size: 1.2rem;
    color: rgb(80, 76, 76);
    //for the dashboard
    z-index: 1;
}

// .brand_footer {
//     display: flex;
//     padding: 1rem;
//     justify-content: space-between;
//     margin-top: auto;
//     background-color: $brand_primary_color;
//     color: $brand_white_color;
//   }
  