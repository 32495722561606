@import "https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0";
@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");

.posts_container {
  margin-left: 10%;
  align-items: center;
  height: 100%;
  //added for bg-color effect
  //height: 100vh;
  background-color: #ebeff7 !important;

  &_title {
    text-transform: uppercase;
    text-align: center;
    font-family: "DM Sans", sans-serif;
    gap: 0.4rem;
    color: rgb(54, 22, 199);
    font-weight: bolder;
    // padding-top: 10px;
  }
  & hr {
    height: 0.1px !important;
    border: 0.01px solid #60b4b1;
  }
  .posts_container_baground {
    //    background-color:red;
    // margin-left: 12%;
    margin: auto;
    display: grid;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    text-align: center;
    border-radius: 2px;
    width: 40%;
    grid-gap: 12px;
    grid-template-columns: repeat(auto-fit,minmax(280px,1fr));

    padding: 16px;
    &_box {
      background-color: #f3f7f7;
      padding: 36px;
      border-radius: 6px;
      box-shadow: 5px 6px 2px 1px #cbd5d4;
    }
  }
}
// & .posts_container_baground{
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-content: center;
//     align-items: center;
//     border-radius: 2px;
//    &img{
//     // margin-left: 20%;
//     // border-color: #60b4b1;
//    }
// }

// .posts_container_baground{
//     background-color:red;
//      display: grid;
//  flex-direction: row;
//  flex-wrap: wrap;
//  justify-content: center;
//  align-content: center;
//  align-items: center;
//  text-align: center;
//  border-radius: 2px;
//  margin-left: 23%;
//  padding: 16px;
// }
